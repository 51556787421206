import React from 'react'

function Footbar() {
  return (

    <div>

    <div>C/Les Escomes 3A</div>
    <div>Riu de Cerdanya - 25721</div>
    <div>+34 649 312 547</div>
    <div><a href="mailto:sergi@consestruccere.com">sergi@consestruccere.com</a></div>
    </div>
  )
}

export default Footbar