import React from 'react'
import '../App.css';


function Navbar() {
  return (
    <div>
        <div className="NavbarLeft">

        <p>Construccions i Estructures Ceretanes S.L.</p>
   

        </div>
        <div className="NavbarRight">

        <p>Promocions, obra nova i rehabilitacions</p>
        
        </div>
        
        
        
    </div>
  )
}

export default Navbar