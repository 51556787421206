import React from 'react'

function Midlebar() {
  return (
    <div>
        <p>Donem forma als teus somnis, idees i projectes</p>
        <p>Som el teu referent amb una amplia experiencia construint a la Cerdanya</p>
        <p>Dispossem d'un gran equip de professionals</p>
        
        <hr className='hr'/>

        
        
        
        
        </div>
  )
}

export default Midlebar